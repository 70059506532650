<template>
  <v-container fluid>
    <v-row no-gutters align="center">
      <v-col cols="2" sm="4">
        <h2>{{ $t('PageTitles.Reports') }}</h2>
      </v-col>
      <v-col cols="4">
        <v-text-field outlined hide-details dense v-model="search" :label="$t('message.Search')" class="pa-4" background-color="searchField" />
      </v-col>
    </v-row>
    <v-data-table
      sort-by="created"
      :footer-props="footerProps"
      :items="projectReport.reports"
      :search="searchHistory"
      :loading="loading"
      :headers="getReportHeaders"
      :items-per-page="5"
      sort-desc
      @click:row="showReport"
    >
      <template #[`item.creator`]="{ item }">
        <router-link v-if="item.creator" :to="{ name: 'UserDetails', params: { Id: item.creator.id } }">
          {{ item.creator.name }}
        </router-link>
      </template>
      <template #[`item.created`]="{ item }">
        {{ $util.formatDate(item.created, true) }}
      </template>
      <template #[`item.projectStatus`]="{ item }">
        {{ $util.projectStatusByNumber(item.projectStatus) }}
      </template>
    </v-data-table>
    <v-row no-gutters align="center" class="mt-8">
      <v-col cols="2" sm="4">
        <h2>{{ $t('PageTitles.ProjectHistory') }}</h2>
      </v-col>
      <v-col cols="4">
        <v-text-field outlined hide-details dense v-model="searchHistory" :label="$t('message.Search')" class="pa-4" background-color="searchField" />
      </v-col>
      <v-col cols="4" align="right" v-if="isManager"> </v-col>
    </v-row>
    <v-data-table
      sort-by="created"
      :footer-props="footerProps"
      :items="filteredRisks"
      :search="searchHistory"
      :loading="loading"
      :headers="getHistoryHeaders"
      @click:row="showRiskDetails"
      :items-per-page="15"
      sort-desc
    >
      <template #[`item.author`]="{ item }">
        <router-link v-if="item.author" :to="{ name: 'UserDetails', params: { Id: item.author.id } }">
          {{ item.author.name }}
        </router-link>
      </template>
      <template #[`item.created`]="{ item }">
        {{ $util.formatDate(item.created, true) }}
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import apiService from '@/services/apiService.js';
export default {
  data: () => ({
    loading: false,
    project: {},
    projectRisksHistory: [],
    projectReport: {},
    AdminOrIsInSameCompany: false,
    search: null,
    show: false,
    searchHistory: '',
    footerProps: { 'items-per-page-options': [5, 15, 30, 50, 100] },
  }),
  computed: {
    ...mapGetters(['currentProjectId', 'isManager', 'isAdmin', 'user']),
    HasFullAccessPermissions() {
      if (this.isAdmin) {
        return true;
      }
      if (this.$util.HasFullAccess(this.user, this.project.permissions) == false) {
        return false;
      } else if (this.AdminOrIsInSameCompany) {
        if (this.isManager) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    getReportHeaders() {
      const t = this.$t.bind(this);
      return [
        { text: t('ReportHeaders.Title'), value: 'reportTitle' },
        { text: t('ReportHeaders.Creator'), value: 'creator' },
        { text: t('ReportHeaders.Created'), value: 'created' },
        { text: t('ReportHeaders.ProjectStatus'), value: 'projectStatus' },
        { text: t('ReportHeaders.Summary'), value: 'summary' },
        { text: t(''), value: 'actions' },
      ];
    },
    getHistoryHeaders() {
      const t = this.$t.bind(this);
      return [
        { text: t('RiskDetails.RiskTitle'), value: 'title' },
        { text: t('RiskListHeaders.EditedBy'), value: 'author' },
        { text: t('RiskListHeaders.Version'), value: 'version' },
        { text: t('ProjectListHeaders.Created'), value: 'created' },
        { text: t('RiskListHeaders.Probability'), value: 'probability' },
        { text: t('RiskListHeaders.TimePlan'), value: 'timeLine' },
        { text: t('RiskListHeaders.Expenses'), value: 'expenses' },
        { text: t('RiskListHeaders.Scope'), value: 'scope' },
        { text: t('RiskListHeaders.Resources'), value: 'resource' },
      ];
    },

    filteredRisks() {
      const sort = this.searchHistory;

      if (sort != '') {
        return this.projectRisksHistory.filter(
          (item) =>
            item.owner.name?.toLowerCase().includes(sort) ||
            item.title?.toLowerCase().includes(sort) ||
            item.mitigation?.toLowerCase().includes(sort) ||
            item.consequenceDescription?.toLowerCase().includes(sort) ||
            item.version?.toString().includes(sort) ||
            item.expenses?.toString().includes(sort) ||
            item.probability?.toString().includes(sort) ||
            item.resource?.toString().includes(sort) ||
            item.riskNumber?.toString().includes(sort) ||
            item.scope?.toString().includes(sort) ||
            item.timeLine?.toString().includes(sort)
        );
      } else {
        return this.projectRisksHistory;
      }
    },
  },
  methods: {
    async getProject() {
      this.loading = true;
      this.project = await apiService.getProjectById(this.currentProjectId);
      this.projectReport = await apiService.getProjectReports(this.currentProjectId);
      this.projectRisksHistory = await apiService.getProjectHistory(this.currentProjectId);
      this.IsAdminOrIsInSameCompany();
      this.loading = false;
    },
    showRiskDetails(risk) {
      this.$router.push({
        name: 'RiskVersionDetails',
        params: { historyId: risk.id },
      });
    },
    showReport(report) {
      this.$router.push({
        name: 'reportDetails',
        params: { reportId: report.id },
      });
    },
    async IsAdminOrIsInSameCompany() {
      if (this.isAdmin) {
        this.AdminOrIsInSameCompany = true;
      }
      if (this.project.companyId != null) {
        if (this.project.companyId == this.user.companyId) {
          this.AdminOrIsInSameCompany = true;
        } else {
          this.AdminOrIsInSameCompany = false;
        }
      } else {
        this.AdminOrIsInSameCompany = false;
      }
    },
  },

  created() {
    this.getProject();
  },
};
</script>

<style>
</style>

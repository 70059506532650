var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"cols":"2","sm":"4"}},[_c('h2',[_vm._v(_vm._s(_vm.$t('PageTitles.Reports')))])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{staticClass:"pa-4",attrs:{"outlined":"","hide-details":"","dense":"","label":_vm.$t('message.Search'),"background-color":"searchField"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{attrs:{"sort-by":"created","footer-props":_vm.footerProps,"items":_vm.projectReport.reports,"search":_vm.searchHistory,"loading":_vm.loading,"headers":_vm.getReportHeaders,"items-per-page":5,"sort-desc":""},on:{"click:row":_vm.showReport},scopedSlots:_vm._u([{key:"item.creator",fn:function(ref){
var item = ref.item;
return [(item.creator)?_c('router-link',{attrs:{"to":{ name: 'UserDetails', params: { Id: item.creator.id } }}},[_vm._v(" "+_vm._s(item.creator.name)+" ")]):_vm._e()]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$util.formatDate(item.created, true))+" ")]}},{key:"item.projectStatus",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$util.projectStatusByNumber(item.projectStatus))+" ")]}}],null,true)}),_c('v-row',{staticClass:"mt-8",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"cols":"2","sm":"4"}},[_c('h2',[_vm._v(_vm._s(_vm.$t('PageTitles.ProjectHistory')))])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{staticClass:"pa-4",attrs:{"outlined":"","hide-details":"","dense":"","label":_vm.$t('message.Search'),"background-color":"searchField"},model:{value:(_vm.searchHistory),callback:function ($$v) {_vm.searchHistory=$$v},expression:"searchHistory"}})],1),(_vm.isManager)?_c('v-col',{attrs:{"cols":"4","align":"right"}}):_vm._e()],1),_c('v-data-table',{attrs:{"sort-by":"created","footer-props":_vm.footerProps,"items":_vm.filteredRisks,"search":_vm.searchHistory,"loading":_vm.loading,"headers":_vm.getHistoryHeaders,"items-per-page":15,"sort-desc":""},on:{"click:row":_vm.showRiskDetails},scopedSlots:_vm._u([{key:"item.author",fn:function(ref){
var item = ref.item;
return [(item.author)?_c('router-link',{attrs:{"to":{ name: 'UserDetails', params: { Id: item.author.id } }}},[_vm._v(" "+_vm._s(item.author.name)+" ")]):_vm._e()]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$util.formatDate(item.created, true))+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }